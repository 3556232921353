<template>
  <div id="app">
  <div class="navbar">
    <router-link to="/" class="button">
      <i class="fa-solid fa-house"></i>
      <span>Strona Główna</span>
    </router-link>
    <router-link to="/regulamin" class="button">
      <i class="fa-solid fa-list"></i>
      <span>Regulamin</span>
    </router-link>
    <a href="https://aplikacje.projectadventure.pl" class="button">
      <i class="fa-solid fa-clipboard"></i>
      <span>Aplikuj na whitelist</span>
    </a>
    <a href="https://discord.gg/projectadventure" class="button">
      <i class="fa-brands fa-discord"></i>
      <span>Discord</span>
    </a>
  </div>

    
    <div class="video-bg">
      <video autoplay muted loop playsinline preload="auto">
        <source src="@/assets/bg.mp4" type="video/mp4">
      </video>
      <div class="overlay"></div>
    </div>

    <router-view /> <!-- To miejsce, w którym będą renderowane komponenty na podstawie tras -->
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });

    document.addEventListener('keydown', function (e) {
      if (e.key === "F12") {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "J")) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.key === "U") {
        e.preventDefault();
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
    document.removeEventListener('keydown', function (e) {
      if (e.key === "F12") {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "J")) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.key === "U") {
        e.preventDefault();
      }
    });
  }
};
</script>

<style scoped>
/* Dodaj stylizację dla navbaru i przycisków, jeśli potrzebujesz */
</style>
