import { createRouter, createWebHistory } from 'vue-router';
import Regulamin from './components/Regulamin.vue'; // Upewnij się, że masz ten komponent
import Glowna from './components/Glowna.vue'; // Upewnij się, że masz ten komponent

const routes = [
{
    path: '/',
    name: 'GlownaPage',
    component: Glowna,
  },
  {
    path: '/regulamin',
    name: 'RegulaminPage',
    component: Regulamin,
  },
  {
    path: '/:catchAll(.*)', // Złap wszystkie nieznane trasy
    redirect: '/', // Przekieruj na stronę główną
    component: Glowna,
  },
  // Możesz dodać inne trasy tutaj
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
