<template>
  <div>
    <div class="regulamin-wrapper">
      <h1>Regulamin</h1>
      <h2>WSTĘP</h2>
      <p><span class="number">1.1</span> Każdy gracz ma obowiązek znać regulamin, którego nieznajomość nie zwalnia z jego przestrzegania.</p>
      <p><span class="number">1.2</span> Dołączenie na serwer Discord jest równoznaczne z akceptacją regulaminu.</p>
      <p><span class="number">1.3</span> Administracja ma prawo karać za czyny, które nie są uwzględnione w regulaminie. Bany permanentne mogą zostać wystawione bez podania przyczyny.</p>
      <p><span class="number">1.4</span> Na serwer można aplikować oraz grać po ukończeniu 16 roku życia.</p>
      <p><span class="number">1.5</span> Aby móc brać udział w rozgrywce na serwerze, gracz musi zdać WhiteList z wynikiem pozytywnym.</p>
      <p><span class="number">1.6</span> Zakazuje się odgrywania dzieci oraz wątków związanych z nieletnimi (w tym ciąży) zgodnie z wytycznymi Rockstar Games i Cfx.re.</p>
      <p><span class="number">1.7</span> Serwer kładzie nacisk na poprawny poziom odgrywania przez graczy.</p>
      <p><span class="number">1.9</span> Każdy gracz musi się upewnić, że jego mikrofon jest w stu procentach sprawny bez żadnych szumów.</p>
      <p><span class="number">1.10</span> Zakazuje się cheatowania oraz modyfikowania plików gry, gracze nie mogą używać jakiegokolwiek oprogramowania ułatwiającego rozgrywkę.</p>

      <h3>Dozwolone modyfikacje:</h3>
      <ul>
          <li>Modyfikowanie dźwięków broni w celu zwiększenia realizmu</li>
          <li>Modyfikacje graficzne, które mają na celu poprawę wyglądu gry</li>
      </ul>

      <h3>Zakazuje się:</h3>
      <ul>
          <li>Modyfikacji graficznych, które dają realną przewagę nad innym graczem np. brak nocy, krzaków itp.</li>
          <li>Zewnętrznych oprogramowań typu: aimbot, dumpery, itd.</li>
      </ul>


      <h2>LORE</h2>
      <p><span class="number">2.1</span> Mapa jest osadzona w stanie Kalifornia, miasto Los Santos w hrabstwie San Andreas w Stanach Zjednoczonych Ameryki. Tereny poza LS (np. Sandy Shores, Paleto Bay) znajdują się w hrabstwie Blaine.</p>
      <p><span class="number">2.2</span> Zachowujemy zasady lore-friendly, czyli nie używamy nazw realnych marek, jeśli chodzi o ubrania, samochody czy inne produkty.</p>
      <p><span class="number">2.3</span> Nie znamy postaci ani wydarzeń z fabuły GTA V.</p>
      <p><span class="number">2.4</span> Nie nawiązujemy w żaden sposób do polskiej kultury. Zakazuje się słuchania polskiej muzyki na serwerze, wyjątkiem są piosenki graczy, które zostały nagrane in game.</p>
      <p><span class="number">2.5</span> Zakazuje się przenoszenia wątków z innych serwerów.</p>


      <h2>NARRACJA</h2>
      <p><span class="number">3.1</span> Podczas używania komend narracyjnych nakazuje się zachowywać poprawną ortografię.</p>
      <p><span class="number">3.2</span> Zakazuje się kłamania na komendach narracyjnych.</p>
      <p><span class="number">3.3</span> Zakazuje się odgrywania głosowo komend narracyjnych.</p>

      <h3>Komendy narracyjne:</h3>
      <ul>
          <li><code>/me</code> - opisuje czynność wykonywaną przez naszą postać.</li>
          <li><code>/do</code> - opisuje stan oraz otoczenie postaci, służy również do zadawania pytań.</li>
          <li><code>/opis</code> - jest to stałe <code>/do</code>, tekst nie znika, jest przypięty do naszej postaci. Aby go usunąć, należy wpisać <code>/opis</code>.</li>
          <li><code>/opis2</code> - jest to stałe <code>/do</code>, zostaje w miejscu, w którym został umieszczony. Aby go usunąć, wrócić w miejsce, gdzie jest, i należy wpisać <code>/opis2</code> bądź <code>/usunopis</code>.</li>
          <li><code>/try</code> - komenda, która służy do wylosowania odpowiedzi, jest to 50/50. Po wpisaniu <code>/try [treść]</code> pojawi nam się odpowiedź "udane"/"nieudane". Zakazuje się "spamowania" komendą <code>/try</code>.</li>
          <li><code>/globaldo</code> - bardziej rozbudowane <code>/do</code>, jest to komenda widoczna dla całego serwera.</li>
      </ul>

      <h2>ROZGRYWKA</h2>
      <p><span class="number">4.1</span> Jeśli akcja RolePlay została przerwana poprzez crash gry lub brak połączenia internetowego, należy zgłosić to na serwerze Discord na kanale #crash.</p>
      <p><span class="number">4.2</span> Zakazuje się wyrzucania przedmiotów za pomocą komend narracyjnych bez pozbycia się ich z ekwipunku.</p>
      <p><span class="number">4.3</span> Zakazuje się teleportowania do szpitala, kiedy wezwano pomoc medyczną lub policję na miejsce zdarzenia, by uniknąć akcji RolePlay.</p>
      <p><span class="number">4.4</span> Zakazuje się rozpoznania zamaskowanej osoby, która zmienia głos. Wyjątkiem są cechy charakterystyczne.</p>
      <p><span class="number">4.5</span> W przypadku chęci odegrania akcji na tle seksualnym należy uzyskać zgodę strony przeciwnej.</p>
      <p><span class="number">4.6</span> Majątki osób martwych i zbanowanych permanentnie przepadają. Wyjątkiem odzyskania majątku jest spisany wcześniej testament.</p>
      <p><span class="number">4.7</span> Odgrywanie osób chorych psychicznie jest dozwolone tylko za zgodą Administracji.</p>

      <h2>SZACUNEK DO ZDROWIA I AMNEZJA</h2>
      <p><span class="number">5.1</span> Zakazuje się powrotu do akcji po BW, każda taka sytuacja będzie karana.</p>
      <p><span class="number">5.2</span> Zakazuje się nieodgrywania bólu.</p>
      <p><span class="number">5.3</span> System amnezji jest dobrowolny. Każdy gracz decyduje, czy odgrywa amnezję, oczywiście biorąc pod uwagę obrażenia postaci.</p>
      <p><span class="number">5.4</span> W przypadku przeteleportowania się na szpital również sami decydujemy o odgrywaniu amnezji.</p>

      <h2>FRAKCJE I POJAZDY</h2>
      <p><span class="number">6.1</span> Zakazuje się odgrywania postaci skorumpowanej we frakcjach. Aby odegrać taką postać, potrzebna jest zgoda administracji.</p>
      <p><span class="number">6.2</span> Aby porwać osobę z frakcji, potrzebne jest odpowiednie podłoże RP.</p>
      <p><span class="number">6.3</span> Aby ukraść pojazd służbowy, należy mieć dobry powód w rozgrywającej się akcji.</p>

      <h2>WAŻNE POJĘCIA</h2>
      <p><span class="number">IC</span> (In Character) - świat gry, w którym żyje nasza postać.</p>
      <p><span class="number">OOC</span> (Out Of Character) - świat, w którym żyjemy my; wszystko, co nie nawiązuje do rozgrywki.</p>
      <p><span class="number">PG</span> (Power Gaming) - wymuszenie na jakimś graczu akcji RP bez możliwości reakcji. Dzielimy na dwa typy: szkodliwy i nieszkodliwy.</p>
      <p><span class="number">MG</span> (MetaGaming) - wykorzystywanie informacji OOC do IC.</p>
      <p><span class="number">RDM</span> (Random Deathmatch) - doprowadzenie do stanu BW randomowych osób (gracze i NPC).</p>
      <p><span class="number">VDM</span> (Vehicle Deathmatch) - doprowadzenie do stanu BW randomowych osób poprzez rozjeżdżanie ich pojazdami (gracz i NPC).</p>
      <p><span class="number">RK</span> (Revenge Kill) - nieuzasadniona zemsta po wyjściu ze szpitala na graczu, który doprowadził Cię do BW.</p>
      <p><span class="number">BW</span> (Brutally Wounded) - wprowadzenie postaci w stan nieprzytomności w skutek odniesionych obrażeń.</p>
      <p><span class="number">CK</span> (Character Kill) - uśmiercenie postaci.</p>
      <p><span class="number">FCK</span> (Force Character Kill) - zaplanowane uśmiercenie innej postaci.</p>
      <p><span class="number">CB</span> (Cop Baiting) - bezcelowe prowokowanie policji.</p>
      <p><span class="number">CL</span> (Combat Log) - wyjście z gry podczas akcji RP.</p>
      <p><span class="number">CN</span> (Celebrity Name) - imię i nazwisko postaci na podstawie znanej osoby.</p>
      <p><span class="number">AMNEZJA</span> - stan, w którym zapominamy poprzednią akcję przed BW.</p>
      <p><span class="number">Zasada poszanowania życia</span> - zasada mówiąca o szacunku do życia postaci i nie narażaniu jej na sytuacje, w których mogłaby dostać uszczerbek na zdrowiu.</p>
      <p><span class="number">FAILRP</span> - błędne odegranie akcji RP, wyjście z roli.</p>
      <p><span class="number">FD</span> (Fail Driving) - jazda pojazdem, która byłaby niemożliwa w prawdziwym życiu.</p>
      <p><span class="number">LOOTRP</span> - nieuzasadnione okradanie innych graczy z przedmiotów.</p>
      <p><span class="number">Meta zwroty</span> - przemycanie informacji OOC za pomocą metafor.</p>
      <p><span class="number">Stream Sniping</span> - wykorzystywanie informacji zdobytych od streamer IC.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'RegulaminPage',
};
</script>

<style scoped>

::-webkit-scrollbar {
  display: none;
}

* {
    font-family: 'Poppins', sans-serif;
}
h1 {
  font-size: 50px;
  color: white;
}
h2 {
  font-size: 20px;
  color: white;
  text-align: center;
}

h3 {
  color: white;
}

p, li {
    color: rgb(255, 255, 255,0.6);
}

.number {
  font-weight: 800;
  color: white;
}

.regulamin-wrapper {
      position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 65%;
    height: 80%;
    overflow-y: auto;
    
}
@media (max-width: 768px) { 
  .regulamin-wrapper {
    width: 90%;
  }
}



</style>
