<template>
  <div>
    <div class="main-wrapper">
      <div class="logo">
        <img src="@/assets/logo2.png" />
      </div>

      <div class="progress">
        <div class="label">
          Coming soon<span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
        </div>

        <div class="progress-bar">
          <div class="progress-val"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlownaPage',
};
</script>

<style scoped>
/* Stylizacje dla Regulaminu */
</style>
