import { createApp } from 'vue';
import App from './App.vue';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/style.css';
import './assets/logo2.png';
import './assets/bg.mp4';

import router from './router'; // Importuj router

createApp(App)
  .use(router) 
  .mount('#app');